export type IGcsPlan = {
  name: string;
  apr: number;
  stakeaddress: string[];
  day: number;
  directbonus: number;
  gcexaddress: string;
  total_staked: number;
  withdrawfee: number;
  plan_id: number;
  rewardaprbonus: number;
  level1DirectBonus: number;
  level2DirectBonus: number;
  xamount: number;
  yamount: number;
  zamount: number;
  validcount: number;
};

export interface IUserStakedDetails {
  amount: number;
  time: number;
  status: Status;
  index: number;
}



export interface IUserDetails {
  ongoingStakingDetails: IUserStakedDetails[];
  finishedStakingDetails: IUserStakedDetails[];
  referrer: string;
  referred_users: string[];
  userRewardDetails: IUserRewardDetails[];
  referralLevel: UserLevel;
  level: UserLevel;
  referralEarnings: number;
}

export type IDepositData = {
  depositAmount: string;
  modal: boolean;
};

export interface IUserReferrals {
  referee_address: string;
  earned: number;
}

export enum BonusType {
  DIRECT = "DIRECT",
  REFERRAL_APR = "REFERRAL_APR",
}

export enum Status {
  PENDING = "PENDING",
  FINISHED = "FINISHED",
}

export enum UserLevel {
  NEWBIE = "NEWBIE",
  BRONZE = "BRONZE",
  SILVER = "SILVER",
  GOLD = "GOLD",
}
export interface IUserRewardDetails {
  address: string;
  earnedBonus: number;
  type: BonusType;
}
// types.ts
export interface IUser {
  id: string;
  name: string;
  email: string;
  referralLevel: LevelStatus;
  totalStakedAmount: number; // Ensure this line is included
  // Add other properties if necessary
}

// types.ts or enums.ts
export enum LevelStatus {
  NEWBIE = 1,    // Level number 1
  SILVER = 2,    // Level number 2
  GOLDEN = 3,    // Level number 3
  BRONZI = 4,    // Level number 4
  DIAMOND = 5    // Level number 5
}
