import React, { useState, useEffect } from "react";
import "./CardWithClock.scss";

const CardWithCountdown: React.FC = () => {
  // Set the target date (10 days from a fixed date)
  const targetDate = new Date("2025-01-01T00:00:00Z").getTime(); // Replace with your desired date
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [isFAQOpen, setIsFAQOpen] = useState<boolean>(false); // State to toggle FAQ visibility

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference <= 0) {
        // Timer expired
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    };

    // Update the countdown every second
    const interval = setInterval(calculateTimeLeft, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [targetDate]);

  // Toggle FAQ visibility
  const toggleFAQ = () => {
    setIsFAQOpen((prevState) => !prevState);
  };

  return (
    <div className="card-countdown">
      <h5>PASSIVE INCOME</h5>
      <div className="countdown-timer">
        <div className="time-box">
          <span className="value">{timeLeft.days}</span>
          <span className="label">Days</span>
        </div>
        <div className="time-box">
          <span className="value">{timeLeft.hours}</span>
          <span className="label">Hours</span>
        </div>
        <div className="time-box">
          <span className="value">{timeLeft.minutes}</span>
          <span className="label">Minutes</span>
        </div>
        <div className="time-box">
          <span className="value">{timeLeft.seconds}</span>
          <span className="label">Seconds</span>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="faq-section">
        <button className="faq-toggle" onClick={toggleFAQ}>
          {isFAQOpen ? "Hide Details" : "Why Wait?"}
        </button>
        {isFAQOpen && (
          <div className="faq-content">
            <h6>Why Do We Need to Wait?</h6>
            <div className="faq-item">
              <strong>1. Launching TRT Token:</strong>
              <p>
                We are preparing to launch our TRT token. With TRT, you can earn in multiple ways: by swapping, staking, trading, spot trading, and even future trading. This opens up several income streams, making it a versatile asset in your investment strategy.
              </p>
            </div>
            <div className="faq-item">
              <strong>2. Introducing a Compounding System:</strong>
              <p>
                We are adding a unique compounding system, allowing you to earn daily profits that can double or triple your investment. This powerful feature will maximize your earnings and accelerate your financial growth.
              </p>
            </div>
            <div className="faq-item">
              <strong>3. Unique Earning Concepts:</strong>
              <p>
                We're working on a one-of-a-kind earning concept that will allow you to unlock massive earning potential. This innovative approach will provide opportunities for significant financial rewards in ways you haven't seen before.
              </p>
            </div>
            <div className="faq-item">
              <strong>4. Bigger Team Benefits:</strong>
              <p>
                The bigger your team, the greater the benefits. If you build a strong team before this update, you will receive extra tokens and rewards. Achieving ranks like Silver or Gold within your team structure will unlock even bigger rewards. As a top leader, you'll receive the largest share of these benefits without any extra cost.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardWithCountdown;
