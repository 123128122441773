import React from "react";
import styles from "./Features.module.scss";

const Features = () => {
  return (
    <div className={styles.features} id="smart-mint">
      <div className="mx pad">
        <section className={styles.features_wrapper} id="features">
          <div className={styles.container}>
            <div className={styles.block_header}>
              <h3 className="block_title mb-8">Why choose our Platform?</h3>
            </div>
            <div className={styles.features_card_wrapper}>
              <div
                className={styles.features_card}
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className={styles.icon}>
                  <span className="icon-security-safe"></span>
                </div>
                <div className={styles.features_content}>
                  <a href="#">Daily Bonus</a>
                  <p className="mb-16 mt-10">
                    Simply hold and stake your assets to receive periodic rewards.
                  </p>
                </div>
              </div>
              <div
                className={styles.features_card}
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className={styles.icon}>
                  <span className="icon-code1"></span>
                </div>
                <div className={styles.features_content}>
                  <a href="#">Referral System</a>
                  <p className="mb-16 mt-10">
                    Enjoy a predictable and steady income stream from your referrals.
                  </p>
                </div>
              </div>
              <div
                className={styles.features_card}
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className={styles.icon}>
                  <span className="icon-lamp-charge"></span>
                </div>
                <div className={styles.features_content}>
                  <a href="#">Easy Claim and Withdraw</a>
                  <p className="mb-16 mt-10">
                    Easily stake and unstake your assets with just a few clicks.
                  </p>
                </div>
              </div>
              <div
                className={styles.features_card}
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className={styles.icon}>
                  <span className="icon-key"></span>
                </div>
                <div className={styles.features_content}>
                  <a href="#">Fully Audited Contracts</a>
                  <p className="mb-16 mt-10">
                    Contracts are fully audited by reliable third-party auditing firms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Features;
