import React from "react";
import styles from "./AboutUs.module.scss";
// import Farms from "../../pages/Home/Farms"; 
const AboutUs = () => {
  return (
    <div className={styles.aboutus} id="about-us">
      <div className={styles.wrapper}>
        <section className={styles.aboutus_wrapper}>
          <div className={styles.container}>
            <div className={styles.row}>
              <div className="col-md-12">
                <div className={styles.block_text}>
                  <h3
                    className={styles.block_title}
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    About Us
                  </h3>
                  <p
                    className={styles.text}
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    The TRUST TRADE system belongs to the latest blockchain
                    technology and is fully decentralized, which means it is
                    transparent and secure.
                  </p>
                  <p
                    className={styles.text}
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    TRUST TRADE is open and available for everyone. You only need a
                    laptop or a smartphone with an internet connection to join
                    and start making a profit. Develop the community, invite
                    partners, and get rewarded.
                  </p>
                </div>
              </div>
            </div>

            <h1
              className={styles.working_title}
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Working Process
            </h1>

            <div
              className={styles.about_card}
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {[
                { step: "Step 1", text: "Register yourself at TRUST TRADE.io" },
                {
                  step: "Step 2",
                  text: "Stake your crypto at TRUST TRADE and claim daily/non-working bonuses.",
                },
                {
                  step: "Step 3",
                  text: "Refer your friends & family and get 3 levels of rewards.",
                },
                {
                  step: "Step 4",
                  text: "Achieve royalty targets and collect 3 levels of rewards.",
                },
              ].map(({ step, text }, index) => (
                <div className={styles.choose__box} key={index} data-aos="fade-up">
                  <div className={styles.choose__box_icon}>
                    <div className={styles.icon}>
                      <span>{step}</span>
                    </div>
                    <div className={styles.content}>
                      <p className={styles.text}>{text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          {/* Render Farms component here */}
          {/* <Farms /> Include Farms component */}

        </section>
      </div>
    </div>
  );
};

export default AboutUs;
