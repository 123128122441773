import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../utils/firebase";
import { v4 } from "uuid";

export const uploadImage = async (id: string, file: File) => {
  const imageref = ref(storage, `Profile/${id}/${v4()}`);
  const uploadTask = uploadBytesResumable(imageref, file);
  await uploadTask;
  const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
  console.log("Download URL:", downloadURL);
  return downloadURL;
};
