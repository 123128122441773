import React from "react";
import "./howitworks.scss";

const HowItWorks = () => {
  return (
    <div className="how-container">
      {/* First Box: How Staking Works */}
      <div className="box" data-aos="fade-up" data-aos-duration="800">
        <h5>How staking in crypto works?</h5>
        <p>
          If you're a crypto investor, staking is a concept you'll hear about
          often. Staking is the way many cryptocurrencies verify their
          transactions, and it allows participants to earn rewards on their
          holdings.
        </p>
        <p>
          Staking cryptocurrencies involves committing your crypto assets to
          support a blockchain network and confirm transactions.
        </p>
      </div>

      {/* Second Box: Benefits of Staking */}
      <div className="box" data-aos="fade-up" data-aos-duration="800">
        <h5>Benefits of staking crypto?</h5>
        <p>
          Here are the benefits of cryptocurrency staking:
        </p>
        <ul>
          <li>Earn passive income on your cryptocurrency holdings.</li>
          <li>No need for specialized equipment like in crypto mining.</li>
          <li>Helps maintain blockchain security and efficiency.</li>
          <li>More environmentally friendly than crypto mining.</li>
        </ul>
      </div>
    </div>
  );
};

export default HowItWorks;
