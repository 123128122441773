import React from "react";
import styles from "./Roadmap.module.scss";

const roadmapData = [
  { date: "Q1 2024", title: "Project Launch", description: "The project goes live with all core features operational." },
  { date: "Q2 2024", title: "Token Distribution", description: "Token distribution begins to early adopters and stakeholders." },
  { date: "Q3 2024", title: "Marketplace Launch", description: "Launch of the marketplace for trading tokens and NFTs." },
  { date: "Q4 2024", title: "Global Expansion", description: "Expanding into international markets with partnerships and collaborations." },
  { date: "Q1 2025", title: "Staking Platform", description: "Introduction of the staking platform for passive income." },
  { date: "Q2 2025", title: "Future Roadmap", description: "Stay tuned for more updates as we continue to innovate." },
];

const Roadmap = () => {
  return (
    <div className={styles.roadmap}>
      <h2 className={styles.title}>Our Roadmap</h2>
      <div className={styles.timeline}>
        {roadmapData.map((milestone, index) => (
          <div className={styles.milestone} key={index}>
            <div className={styles.date}>{milestone.date}</div>
            <div className={styles.details}>
              <h4>{milestone.title}</h4>
              <p>{milestone.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
