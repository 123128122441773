import React from "react";
import "./Home.scss";
import Farm from "./Farm";
import Farms from "./Farms";


const Home: React.FC = () => {
  return (
  
    <div className="pad">
      <div className="home">
        <Farm />
      </div>
      {/* <div className="home-page">
        <Farms />
      </div> */}
    </div>



  );

  
};

export default Home;
