import React, { useCallback, useState } from "react";
import Modal from "../Modal";
import Button from "../Button";
import Dropzone, { useDropzone } from "react-dropzone";
import gallery from "../../assets/icons/gallery.png";
import "./ProfileDetails.scss";
import { uploadImage } from "../../services/storageService";
import { updateUserDoc } from "../../services/userService";
import { useWeb3React } from "@web3-react/core";
import { useUserStore } from "../../store/userStore";

interface IChangeCoverProps {
  onClose: () => void;
  onUpdate: () => Promise<void>;
}

const ChangeCover: React.FC<IChangeCoverProps> = ({ onClose, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<{ file: File; url: string }>();
  const [error, setError] = useState<string | null>(null);
  const { account } = useWeb3React();
  const setIsValid = useUserStore((s) => s.setIsValid);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      if (file.size > 5 * 1024 * 1024) {
        setError("File size exceeds 5MB. Please select a smaller file.");
        return;
      }
      setError(null);
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        if (typeof binaryStr === "string") {
          setImage({ file: file, url: binaryStr });
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleBannerUpload = async () => {
    try {
      if (!account || !image) return;

      setLoading(true);
      const url = await uploadImage(account, image.file);
      await updateUserDoc(account, { bannerImg: url });
      setIsValid(false);
      console.log("DOC UPDATED");
      await onUpdate();
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={true} className="default" handleClose={onClose}>
      <div className="edit-profile-images">
        <div className="image-upload">
          {image ? (
            <div className="preview-image" {...getRootProps()}>
              <img src={image.url} alt="" />
              <input {...getInputProps()} />
              {error && <p style={{ color: "tomato" }}>{error}</p>}
              <div className="drop-btn">
                <p>Drop here or Select file</p>
              </div>
              <Button
                variant="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleBannerUpload();
                }}
                disabled={loading}
              >
                {loading ? "Uploading..." : "Upload"}
              </Button>
            </div>
          ) : (
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src={gallery} alt="" />
                    <p>
                      PNG, JPEG, JPG, WebP, GIF, AAC, FLAC, M4A, MPEG, WAV, MOV, QUICKTIME, MP4 or
                      WEBM. Max 5 MB.
                    </p>
                    {error && <p style={{ color: "tomato" }}>{error}</p>}
                    <Button variant="primary">Drop here or Select file</Button>
                  </div>
                </section>
              )}
            </Dropzone>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ChangeCover;
