import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Backdrop from './Backdrop';
import './Modal.scss';
import { modalVaraints } from '../../helpers/animation';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as NoWalletIllustration } from '../../assets/icons/nowallet_illustration.svg';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import Button from '../Button';

interface INoWalletModal {
  modal: boolean;
  handleClose?: () => void;
}

const NoWalletModal: React.FC<INoWalletModal> = ({ modal, handleClose }) => {
  return (
    <Backdrop handleClose={handleClose} isOpen={modal}>
      <AnimatePresence exitBeforeEnter>
        {modal && (
          <motion.div
            className={'nowallet_modal'}
            onClick={(e) => e.stopPropagation()}
            variants={modalVaraints}
            animate="animate"
            initial="initial"
            exit="exit"
          >
            <div className="nowallet_modal-content">
              <div className="modal-header">
                <h3>No Wallet</h3>
                <div
                  className="close"
                  onClick={() => handleClose && handleClose()}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className="content">
                <NoWalletIllustration />
                <p>Don't have a wallet? Download MetaMask here...</p>
                <a href="https://metamask.io/download/">
                  <Button>
                    <Download />
                    <span>Download</span>
                  </Button>
                </a>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Backdrop>
  );
};

export default NoWalletModal;
