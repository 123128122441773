import React, { useEffect } from 'react';

const ChatWidget: React.FC = () => {
  useEffect(() => {
    // Initialize the Crisp chat widget
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "13389769-f570-4623-bed6-ef880815cbac";

    // Create script element for Crisp
    const script = document.createElement('script');
    script.src = 'https://client.crisp.chat/l.js';
    script.async = true;

    // Append the script to the document head
    document.getElementsByTagName('head')[0].appendChild(script);

    // Cleanup on component unmount
    return () => {
      const crispScripts = document.querySelectorAll(
        'script[src="https://client.crisp.chat/l.js"]'
      );
      crispScripts.forEach((script) => script.remove());
    };
  }, []);

  return null; // This component doesn't render anything on the UI
};

export default ChatWidget;
