import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import Button from "../Button";
import "./ProfileDetails.scss";
import { useWeb3React } from "@web3-react/core";
import { updateUserDoc } from "../../services/userService";
import { useUserStore } from "../../store/userStore";

interface IEditProfileProps {
  onClose: () => void;
  onUpdate?: () => Promise<void>;
}

const EditProfile: React.FC<IEditProfileProps> = ({ onClose }) => {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const { account } = useWeb3React();
  const userData = useUserStore((s) => s.userData);
  const setIsValid = useUserStore((s) => s.setIsValid);

  useEffect(() => {
    if (userData) {
      setUsername(userData?.username ?? "");
    }
  }, [userData]);

  const handleSubmit = async () => {
    try {
      if (!account) return;

      setLoading(true);
      await updateUserDoc(account, { username: username });
      console.log("DOC UPDATED");
      setIsValid(false);
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen className="default" handleClose={onClose}>
      <div className="edit-profile-wrapper">
        <h5>Edit</h5>
        <input
          type="text"
          placeholder="User name"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <div className="edit-btn">
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? "Saving your changes..." : "Edit"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditProfile;
