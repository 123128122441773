const TOKEN_ADDRESS = {
  56: "0x55d398326f99059fF775485246999027B3197955",
  4002 : "0xD974d9B3b48f6b4F9f473Ce3c175670b0A60A16a",
};

const STAKING_ADDRESS = {
  56: "0x41Bd7053bf40e307559bA6E7C71943dF0A8aaC92",
  4002 : "0xefd33638d30e4d625f1b6535cf0781267f52f177",
};

export { TOKEN_ADDRESS, STAKING_ADDRESS };
