import { ethers, utils } from "ethers";
import {
  BonusType,
  IUserReferrals,
  IUserRewardDetails,
  Status,
  UserLevel,
} from "../store/types";
import { STAKING_ADDRESS } from "./address";
import { sleep } from "./contractMethods";
import { loadStakingContract, loadTokenContract } from "./contracts";

// Utility function for formatting Ether values
export const formatEther = (value: string): string => utils.formatEther(value);

// Map for user levels
const UserLevelStatus: Record<number, UserLevel> = {
  0: UserLevel.NEWBIE,
  1: UserLevel.BRONZE,
  2: UserLevel.SILVER,
  3: UserLevel.GOLD,
};





// Get user token balance
export const getUserTokenBalance = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<number> => {
  const tokenContract = loadTokenContract(address, provider, chainId);
  const tokenBalance = await tokenContract.balanceOf(address);
  const formattedTokenBalance = utils.formatEther(tokenBalance.toString());
  return Number(formattedTokenBalance);
};

// Get user allowance
export const getUserAllowance = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<number> => {
  const tokenContract = loadTokenContract(address, provider, chainId);
  const userAllowanceInHex = await tokenContract.allowance(
    address,
    STAKING_ADDRESS[chainId]
  );
  const formatEther = utils.formatEther(userAllowanceInHex.toString());
  return Number(formatEther);
};

// Set approval for token transfer
export const setApprove = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<void> => {
  const tokenContract = loadTokenContract(address, provider, chainId);
  const tx = await tokenContract.approve(
    STAKING_ADDRESS[chainId],
    ethers.constants.MaxUint256
  );
  await tx.wait();
  await sleep();
};

// Get rewards
export const getRewards = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number,
  id: number
): Promise<{
  totalRewardsWithInitialAmount: number;
  totalRewards: number;
  dailyReward: number;
}> => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const rewards = await stakingContract.calculateReward(id, address);

  return {
    totalRewardsWithInitialAmount: Number(formatEther(rewards[0].toString())),
    totalRewards: Number(formatEther(rewards[1].toString())),
    dailyReward: Number(formatEther(rewards[2].toString())),
  };
};


// Get user staking details
export const getUserStakingDetails = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<{
  totalStaked: number;
  ongoingStakingDetails: { amount: number; time: number; status: Status; index: number }[];
  finishedStakingDetails: { amount: number; time: number; status: Status; index: number }[];
  referred_users: string[];
  referrer: string;
  referralLevel: UserLevel;
  level: UserLevel;
  referralEarnings: number;
}> => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const details: any[] = await stakingContract.getUserStakes(address);
  const totalStaked = await stakingContract.getUserTotalStaked(address);
  const referred_users = await stakingContract.getUserReferralList(address);
  const userLevel = await stakingContract.getUserLevel(address);
  const referralLevel = await stakingContract.getReferralLevel(address);
  const referrer = await stakingContract.getReferrerAddress(address);
  const referralEarnings = await stakingContract.getReferralEarnings(address);

  const modifiedData = details.map((d, index) => ({
    amount: Number(formatEther(d.amount.toString())),
    time: Number(d.time.toString()) * 1000,
    status: d.status.toString() === "0" ? Status.PENDING : Status.FINISHED,
    index,
  }));

  return {
    totalStaked: Number(formatEther(totalStaked.toString())),
    ongoingStakingDetails: modifiedData.filter(
      (f) => f.status === Status.PENDING
    ),
    finishedStakingDetails: modifiedData.filter(
      (f) => f.status === Status.FINISHED
    ),
    referred_users,
    referrer,
    referralLevel: UserLevelStatus[referralLevel],
    level: UserLevelStatus[userLevel],
    referralEarnings: Number(formatEther(referralEarnings.toString())),
  };
};

// Get user total staked
export const getUserTotalStaked = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<string> => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const totalStaked = await stakingContract.getUserTotalStaked(address);
  return formatEther(totalStaked);
};

// Get user referrals
export const getUserReferrals = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<IUserReferrals[]> => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const referrerAddresses = await stakingContract.getstakeReferrers(address);

  const referralsList: IUserReferrals[] = await Promise.all(
    referrerAddresses.map(async (ref) => {
      const data = await stakingContract.getStakeAmount(address, ref);
      const formattedBalance = ethers.utils.formatEther(data.toString());
      return {
        referee_address: ref,
        earned: Number(formattedBalance),
      };
    })
  );

  return referralsList;
};

// Get referrer address
export const getReferrerAddress = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number,
  id: string
): Promise<string> => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const referrerAddresses = await stakingContract.getAddress(id);
  return referrerAddresses.toString();
};

// Get user reward details
export const getUserRewardDetails = async (
  stakingContract: ethers.Contract,
  address: string
): Promise<IUserRewardDetails[]> => {
  const data = await stakingContract.getRewardAmount(address);

  return data.map((d) => ({
    address: d._address.toLowerCase(),
    earnedBonus: formatEther(d.amount.toString()),
    type: d[0].toString() === "0" ? BonusType.DIRECT : BonusType.REFERRAL_APR,
  }));
};

// Get user partner referrer income in the last 24 hours
export const getUserPartnerReferrerIncome24hrs = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<string> => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const amount = await stakingContract.getPartnerReferrerIncome24hrs(address);
  return formatEther(amount.toString());
};

// Get user direct referrer income in the last 24 hours
export const getUserDirectReferrerIncome24hrs = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<string> => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const amount = await stakingContract.getDirectReferrerIncome24hrs(address);
  return formatEther(amount.toString());
};

// Get user direct referrer count
export const getUserDirectReferrerCount = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<string> => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const referrerCount = await stakingContract.getUserReferralListCount(address);
  return referrerCount.toString();
};

// Get user partner referrer count
export const getUserPartnerReferrerCount = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<string> => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const partnerCount = await stakingContract.getPartnerReferrerCount(address);
  return partnerCount.toString();
};

// Calculate total income
// Function to get total claimed income
export const getUserTotalClaimed = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<number> => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const claimedIncome = await stakingContract.getUserTotalClaimed(address);
  return Number(utils.formatEther(claimedIncome.toString()));
};

// Updated getTotalIncome function
export const gettotalWithdrawals = async (
  address: string,
  provider: ethers.providers.JsonRpcProvider,
  chainId: number
): Promise<number> => {
  // Existing income calculations
  const directIncome = await getUserDirectReferrerIncome24hrs(address, provider, chainId);
  const partnerIncome = await getUserPartnerReferrerIncome24hrs(address, provider, chainId);
  const totalClaimedIncome = await getUserTotalClaimed(address, provider, chainId);

  const totalIncome = Number(directIncome) + Number(partnerIncome) + totalClaimedIncome;

  return totalIncome;
};