import { ethers } from 'ethers'; // Add this import to make it a module
import stakingAbi from './abi/stakingAbi.json';

// Get User Activity
export const getUserActivity = async (
  contractAddress: string,
  provider: ethers.providers.JsonRpcProvider,
  userAddress: string
) => {
  if (!ethers.utils.isAddress(userAddress)) {
    console.error('Invalid user address:', userAddress);
    return [];
  }

  const contract = new ethers.Contract(contractAddress, stakingAbi, provider);

  try {
    const currentBlock = await provider.getBlockNumber();
    console.log('Current Block:', currentBlock);

    // Fetching Events Filtered by User Address
    const eventsToFetch = [
      { filter: contract.filters.Staked(userAddress), eventName: 'Staked' },
      { filter: contract.filters.Reward(userAddress), eventName: 'Reward' },
      { filter: contract.filters.Claimed(userAddress), eventName: 'Claimed' },
      { filter: contract.filters.Withdrawn(userAddress), eventName: 'Withdrawn' },
      { filter: contract.filters.ReferralEarningsWithdrawn(userAddress), eventName: 'ReferralEarningsWithdrawn' }
    ];

    // Fetch all events for the given user address
    const allEvents = await Promise.all(
      eventsToFetch.map(async ({ filter, eventName }) => {
        const events = await contract.queryFilter(filter, currentBlock -  currentBlock);
        return Promise.all(
          events.map(async (event) => {
            const block = await provider.getBlock(event.blockNumber);
            return {
              name: eventName,
              args: event.args ?? {},
              blockNumber: event.blockNumber,
              timestamp: new Date(block.timestamp * 1000).toLocaleString(),
            };
          })
        );
      })
    );

    const flatEvents = allEvents.flat();

    return flatEvents.sort((a, b) => b.blockNumber - a.blockNumber);
  } catch (error) {
    console.error('Error fetching user activity:', error);
    return [];
  }


  
};

// Add an empty export statement to treat this file as a module
export {};
