import React, { useEffect, useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import styles from "./Hero.module.scss";
import { Link } from "react-router-dom";

const Hero = () => {
  const [isTrue, setIsTrue] = useState(false);

  useEffect(() => {
    setIsTrue(true);
  }, []);

  // Set the countdown target to 5 days from now
  const fiveDaysInMilliseconds = 14 * 24 * 60 * 60 * 1000;
  const targetDate = Date.now() + fiveDaysInMilliseconds;

  // const countdownRender = ({
  //   completed,
  //   days,
  //   minutes,
  //   seconds,
  //   hours,
  // }: CountdownRenderProps) => {
  //   if (completed) {
  //     return (
  //       <>
  //         <div data-value="Days">
  //           <span className={styles.countdown__value}>00</span>
  //         </div>
  //         <div data-value="Hours">
  //           <span className={styles.countdown__value}>00</span>
  //         </div>
  //         <div data-value="Minutes">
  //           <span className={styles.countdown__value}>00</span>
  //         </div>
  //         <div data-value="Seconds">
  //           <span className={styles.countdown__value}>00</span>
  //         </div>
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         {days > 0 && (
  //           <div data-value="Days">
  //             <span className={styles.countdown__value}>
  //               {days < 10 ? `0${days}` : days}
  //             </span>
  //           </div>
  //         )}
  //         <div data-value="Hours">
  //           <span className={styles.countdown__value}>
  //             {hours < 10 ? `0${hours}` : hours}
  //           </span>
  //         </div>
  //         <div data-value="Minutes">
  //           <span className={styles.countdown__value}>
  //             {minutes < 10 ? `0${minutes}` : minutes}
  //           </span>
  //         </div>
  //         <div data-value="Seconds">
  //           <span className={styles.countdown__value}>
  //             {seconds < 10 ? `0${seconds}` : seconds}
  //           </span>
  //         </div>
  //       </>
  //     );
  //   }
  // };

  return (
    <div className={styles.hero}>
      <div className="mx pad">
        <div className={styles["banner-top__content"]}>
          <h2
            className={styles["title"]}
            data-aos="fade-up"
            data-aos-duration="800"
          >
            TRUST TRADE <br /> STAKE
          </h2>
          <p
            className={styles["desc"]}
            data-aos="fade-up"
            data-aos-duration="800"
          >
           <h5>TRUST TRADE is a global Cryptocurrency whose members help each other to
            achieve financial well-being using our web3.0 and algorithm of
            instant profit distribution on smart contracts.</h5>
          </p>

          <div
            className={styles.countdown}
            data-aos="fade-up"
            data-aos-duration="800"
          >
            {/* <Countdown date={targetDate} renderer={countdownRender} /> */}
          </div>

          <div
            className={styles.controls}
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <Link to="/">
              <button>Take Step</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
