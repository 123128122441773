import React, { ReactNode, createContext, useState, useEffect, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { getUserAllowance, getUserTokenBalance } from "../../utils/userMethods";
import { getUserStakingDetails } from "../../utils/userMethods";
import { IUserDetails, UserLevel } from "../types";

const MINIMUM_ALLOWANCE_AMOUNT = 1;
interface IUser extends IUserDetails {
  tokenBalance: number;
  userAllowance: number;
  isAllowanceApproved: boolean;
}

interface IUserContext {
  isLoading: boolean;
  setUserData: React.Dispatch<React.SetStateAction<IUser>>;
  userData: IUser;
  refetch: () => Promise<void>;
}

const initialState = {
  tokenBalance: 0,
  userAllowance: 0,
  isAllowanceApproved: false,
  referrer: "",
  referred_users: [],
  ongoingStakingDetails: [],
  finishedStakingDetails: [],
  userRewardDetails: [],
  referralLevel: UserLevel.NEWBIE,
  referralEarnings: 0,
  level: UserLevel.NEWBIE,
};

export const UserContext = createContext<IUserContext>({
  userData: initialState,
  setUserData: () => {},
  isLoading: false,
  refetch: async () => {},
});

const UserContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<IUser>(initialState);
  const { account, library, chainId } = useWeb3React();

  const handleGetUserData = useCallback(async () => {
    if (account && chainId) {
      const { provider } = library;
      try {
        setIsLoading(true);
        const userAllowance = await getUserAllowance(account, provider, chainId);
        if (userAllowance >= MINIMUM_ALLOWANCE_AMOUNT) {
          const userStakingDetails = await getUserStakingDetails(account, provider, chainId);
          return setUserData({
            ...userData,
            tokenBalance: await getUserTokenBalance(account, provider, chainId),
            userAllowance,
            isAllowanceApproved: userAllowance >= MINIMUM_ALLOWANCE_AMOUNT,
            ...userStakingDetails,
          });
        }
        setUserData({
          ...userData,
          tokenBalance: await getUserTokenBalance(account, provider, chainId),
          userAllowance,
          isAllowanceApproved: userAllowance >= MINIMUM_ALLOWANCE_AMOUNT,
        });
      } catch (error: any) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, library, chainId]);

  useEffect(() => {
    handleGetUserData();
  }, [handleGetUserData]);

  return (
    <UserContext.Provider value={{ userData, setUserData, isLoading, refetch: handleGetUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider; 