import React, { useState, useContext, useEffect } from "react";
import { ethers, Contract } from "ethers";
import { Button } from "../../components";
import { TransactionContext } from "../../store/context/TransactionContext";
import { useSnackbar } from 'notistack';
import { IDepositData } from "../../store/types";
import styles from "./Deposit.module.scss";

// Token and Contract Addresses
const USDT_ADDRESS = "0x741F3c2B0BdD5D495eF893004b0EB1EaB07B8919";
const TOKEN_ADDRESS = "0xD974d9B3b48f6b4F9f473Ce3c175670b0A60A16a";
const CONTRACT_ADDRESS = "0x6468a3dA09E6AF89d11130BaC8140296ea4adA03";

const Deposit: React.FC<{ setDepositModal: React.Dispatch<React.SetStateAction<IDepositData | null>> }> = ({ setDepositModal }) => {
  const { loading } = useContext(TransactionContext);
  const { enqueueSnackbar } = useSnackbar();
  
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [tradeHistory, setTradeHistory] = useState<{ amount: number, price: number, time: string }[]>([]);

  const [usdtAmount, setUsdtAmount] = useState<string>("");
  const [tokenAmount, setTokenAmount] = useState<string>("");
  const [tokenPrice, setTokenPrice] = useState<string | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [usdtContract, setUsdtContract] = useState<Contract | null>(null);
  const [tokenContract, setTokenContract] = useState<Contract | null>(null);
  const [contract, setContract] = useState<Contract | null>(null);

  useEffect(() => {
    if (window.ethereum) {
      const init = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        setProvider(provider);

        const usdt = new ethers.Contract(USDT_ADDRESS, [
          "function allowance(address owner, address spender) external view returns (uint256)",
          "function approve(address spender, uint256 amount) external returns (bool)",
          "function transferFrom(address sender, address recipient, uint256 amount) external returns (bool)",
          "function balanceOf(address account) external view returns (uint256)",
        ], signer);
        setUsdtContract(usdt);

        const token = new ethers.Contract(TOKEN_ADDRESS, [
          "function transfer(address recipient, uint256 amount) external returns (bool)",
          "function balanceOf(address account) external view returns (uint256)",
        ], signer);
        setTokenContract(token);

        const tradingContract = new ethers.Contract(CONTRACT_ADDRESS, [
          "function buyTokens(uint256 usdtAmount) external",
          "function sellTokens(uint256 tokenAmount) external",
          "function tokenPrice() external view returns (uint256)",
          "function isBuyingAllowed() external view returns (bool)",
          "function isSellingAllowed() external view returns (bool)",
        ], signer);
        setContract(tradingContract);

        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        setAccount(accounts[0]);

        const price = await tradingContract.tokenPrice();
        setTokenPrice(ethers.utils.formatUnits(price, 18));
      };
      init();
    } else {
      alert("Please install MetaMask or another Web3 wallet");
    }
  }, []);

  const handleDeposit = () => {
    if (selectedAmount !== null) {
      setDepositModal({
        modal: true,
        depositAmount: selectedAmount.toString(),
      });

      // // Add to trade history
      // const newTrade = {
      //   amount: selectedAmount,
      //   price: 50000 + Math.random() * 2000, // Random price for the demo
      //   time: new Date().toLocaleString(),
      // };
      // setTradeHistory([newTrade, ...tradeHistory]);

      // setShowConfirmation(false);
    }
  };

  const handleBuyTokens = async () => {
    if (!contract || !usdtContract) return;
    if (!usdtAmount || parseFloat(usdtAmount) <= 0) {
      enqueueSnackbar("Please enter a valid USDT amount.", { variant: 'error' });
      return;
    }
    try {
      setLoadingAction(true);
      const usdtAmountWei = ethers.utils.parseUnits(usdtAmount, 18);

      const allowance = await usdtContract.allowance(account, CONTRACT_ADDRESS);
      if (allowance.lt(usdtAmountWei)) {
        await handleApprove(usdtAmount);
      }

      const tx = await contract.buyTokens(usdtAmountWei);
      await tx.wait();
      enqueueSnackbar("Tokens bought successfully!", { variant: 'success' });
      setUsdtAmount("");
    } catch (err) {
      enqueueSnackbar("Failed to buy tokens.", { variant: 'error' });
    } finally {
      setLoadingAction(false);
    }
  };

  const handleApprove = async (amount: string) => {
    if (!usdtContract) return;
    try {
      const tx = await usdtContract.approve(CONTRACT_ADDRESS, ethers.utils.parseUnits(amount, 18));
      await tx.wait();
      enqueueSnackbar("USDT allowance approved.", { variant: 'success' });
    } catch (err) {
      enqueueSnackbar("Failed to approve USDT allowance.", { variant: 'error' });
    }
  };

  return (
    <div className={styles.container}>

      {/* Deposit and Staking Section */}
      <div className={styles.tradeBox}>
        <h3 className={styles.title}>Deposit and Stake Tokens</h3>

        <input
          type="number"
          placeholder="Enter amount in USDT"
          onChange={(e) => setSelectedAmount(Number(e.target.value))}
          className={styles.amountInput}
        />

        <Button
          onClick={() => setShowConfirmation(true)}
          disabled={loadingAction || loading}
          className={styles.buyButton}
        >
          Deposit USDT
        </Button>
      </div>

      {/* Deposit Confirmation Modal */}
      {showConfirmation && (
        <div className={styles.confirmationModal}>
          <h4>Confirm Your Deposit</h4>
          <p>Amount: {selectedAmount} USDT</p>
          <div className={styles.modalButtons}>
            <Button onClick={handleDeposit}>Confirm</Button>
            <Button onClick={() => setShowConfirmation(false)} className={styles.cancelButton}>Cancel</Button>
          </div>
        </div>
      )}

      {/* Trade History */}
      {/* <div className={styles.historyBox}>
        <h3 className={styles.historyTitle}>Trade History</h3>
        <div className={styles.historyList}>
          {tradeHistory.map((trade, index) => (
            <div key={index} className={styles.historyItem}>
              <span>{trade.time}</span>
              <span>{trade.amount} USDT</span>
              <span>@ {trade.price.toFixed(2)} USDT</span>
            </div>
          ))} */}
        {/* </div> */}
      {/* </div> */}

      {/* Token Buy Section */}
      
      {/* Token Price */}
     

    </div>
  );
};

export default Deposit;
