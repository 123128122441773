import React from "react";
import Hero from "../../components/Hero";
import AboutUs from "../../components/AboutUs";
import Features from "../../components/Features";

import ContactUs from "../../components/ContactUs";
import Faq from "../../components/FAQ";
import HowItWorks from "../../components/HowItWorks";
import Roadmap from "../../components/Roadmap";
// import DropdownMenu from "./components/DropdownMenu";

const LandingPage = () => {
  return (
    <div>
      <Hero />
      <AboutUs />
      <HowItWorks />
      <Features />
      <Roadmap />
      <Faq />
      <ContactUs />
      {/* <DropdownMenu /> */}
    </div>
  );
};

export default LandingPage;
