import React, { useCallback, useEffect, useState } from "react";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import "./Header.scss";
import { UnlockWallet } from "../";
import logo from "../../assets/logo/logo.png";
import { useUpdateEffect } from "../../hooks";
import { switchNetwork } from "../../utils/connector";
import AccountModal from "../Modals/AccountModal";
import { ReactComponent as Account } from "../../assets/icons/account.svg";
import NoWalletModal from "../Modals/NoWalletModal";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { useUserStore } from "../../store/userStore";
import { getUserDoc } from "../../services/userService";
import { Link } from "react-router-dom";
import SideMenu from "../SideMenu"; // Import the SideMenu component

const Header: React.FC = () => {
  const { account, error } = useWeb3React();
  const [accountModal, setAccountModal] = useState(false);
  const [nowalletModal, setNowalletModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for side menu visibility
  const setUserData = useUserStore((store) => store.setUserData);
  const isValid = useUserStore((s) => s.isValid);
  const setIsValid = useUserStore((s) => s.setIsValid);

  useUpdateEffect(() => {
    if (error && error instanceof UnsupportedChainIdError) switchNetwork();
    if (error && error instanceof NoEthereumProviderError)
      setNowalletModal(true);
  }, [error]);

  const handleGetData = useCallback(async () => {
    try {
      if (!account) return;
      const data = await getUserDoc(account);
      console.log(data);
      setUserData(data);
      setIsValid(true);
    } catch (error) {
      console.log(error);
    }
  }, [account, setUserData, setIsValid]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  useEffect(() => {
    if (!isValid) {
      handleGetData();
    }
  }, [isValid, handleGetData]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header className="header pad">
        <div className="logo">
          {/* Menu button to toggle the side menu */}
          <button className="menu-button" onClick={toggleMenu}>
            ☰
          </button>
          <Link to="/landingpage">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="block_right">
          {account ? (
            <>
              {/* <Link to="/profile">Profile</Link> */}
              <button
                className="account-btn"
                onClick={() => setAccountModal(true)}
              >
                <Account />
                <span>
                  {`${account.slice(0, 6)}...${account.slice(
                    account.length - 6
                  )}`}{" "}
                </span>
              </button>
            </>
          ) : (
            <UnlockWallet />
          )}
        </div>
      </header>
      {/* Side Menu Component */}
      <SideMenu isOpen={isMenuOpen} onClose={toggleMenu} />

      <AccountModal
        modal={accountModal}
        handleClose={() => setAccountModal(false)}
      />
      <NoWalletModal
        modal={nowalletModal}
        handleClose={() => setNowalletModal(false)}
      />
    </>
  );
};

export default Header;
