import React from "react";
import styles from "./SideMenu.module.scss";
import { Link } from "react-router-dom";

interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ isOpen, onClose }) => {
  const handleLinkClick = () => {
    onClose(); // Automatically close the menu when a link is clicked
  };

  return (
    <div className={`${styles.sideMenu} ${isOpen ? styles.open : ""}`}>
      <button className={styles.closeButton} onClick={onClose}>
        i
      </button>
      <nav className={styles.menuNav}>
        <Link to="/" onClick={handleLinkClick}>
          Overview
        </Link>
        <Link to="/transactions" onClick={handleLinkClick}>
          History
        </Link>
        <Link to="/token-info" onClick={handleLinkClick}>
          Rewards
        </Link>
        <Link to="/usdt" onClick={handleLinkClick}>
          Buy TRT
        </Link>
        <Link to="/profile" onClick={handleLinkClick}>
          Settings
        </Link>
        {/* <Link to="/admin" onClick={handleLinkClick}>
          Admin
        </Link> */}
      </nav>
    </div>
  );
};

export default SideMenu;
