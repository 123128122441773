import { create } from "zustand";
import { IUserProfileData } from "../constants/types";

type State = {
  userData: IUserProfileData | null;
  isValid: boolean;
};

type Action = {
  setUserData: (status: IUserProfileData) => void;
  setIsValid: (state: boolean) => void;
};

export const useUserStore = create<State & Action>((set) => ({
  userData: null,
  isValid: true,
  setUserData: (data) => set(() => ({ userData: data })),
  setIsValid: (data) => set(() => ({ isValid: data })),
}));
