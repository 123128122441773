import React, { useContext, useState, useCallback, useEffect } from "react";
// import { FaRocket, FaUsers, FaDollarSign, FaRegMoneyBillAlt } from 'react-icons/fa';
import FarmHeader from "../Home/FarmStats";
import CryptoSwapAndStake from "../ProfilePage/CryptoSwapAndStake";
import { useSnackbar } from 'notistack';

import ProfileImg from "../../assets/images/profile.jpeg";
import "./ProfilePage.scss";
import ChangeCover from "../../components/ProfileDetails/ChangeCover";
import ChangeProfileImage from "../../components/ProfileDetails/ChangeProfileImage";
import EditProfile from "../../components/ProfileDetails/EditProfile";
import { useUserStore } from "../../store/userStore";
import { formatAddress } from "../../utils/helpers";
import { useWeb3React } from "@web3-react/core";
import Farms from "../../pages/Home/Farms";
import { useUserStakingData } from "../../hooks";
import BottomMenu from "../../components/BottomMenu";
import ReferralsModal from "../../components/Modals/ReferralModal";
import { withdrawReferralEarnings, checkUserLevel , claimAll, checkStatus,  getEarningsAll, getTotalWithdrawals  } from "../../utils/contractMethods";
import { IDepositData } from "../../store/types";
import DepositModal from "../../components/Modals/DepositModal";
// import FarmStats from "../Home/FarmStats";
import { TransactionContext } from "../../store/context/TransactionContext";
// import { AnimatePresence } from "framer-motion";
// import { Button, UnlockWallet } from "../../components";
// import FarmHeader from "../Home/FarmHeader";
// import { ReactComponent as Loader } from "../../assets/icons/transaction_processing.svg";
// import Deposit from "../Home/Deposit";

import {
  getUserDirectReferrerIncome24hrs,
  getUserPartnerReferrerIncome24hrs,
  getUserDirectReferrerCount,
  getUserPartnerReferrerCount,
  getUserTotalStaked,
  getUserStakingDetails,
  getUserTotalClaimed,
  // getRewards
} from "../../utils/userMethods";
import CardWithClock from "../../CardWithClock";
import SmartContractIntegration from "../../utils/PartnerDetails";
import TokenTradingApp from "../../utils/PartnerDetails";
import FarmStats from "../Home/FarmStats";

const ProfilePage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar(); // hook to trigger notifications

  const [depositModal, setDepositModal] = useState<IDepositData | null>(null);
  const [openBannerModal, setOpenBannerModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  // const [earningsLast24Hours, setEarningsLast24Hours] = useState<number>(0);
  const [openReferralsModal, setOpenReferralsModal] = useState(false);
  const [userStatus, setUserStatus] = useState<string>('');
  const [userLevel, setUserLevel] = useState<string>('');

  const userData = useUserStore((store) => store.userData);
  const { account, library, chainId } = useWeb3React();
  const [partnerIncome, setPartnerIncome] = useState(0);
  const [directIncome, setDirectIncome] = useState(0);
  const [previousPartnerIncome, setPreviousPartnerIncome] = useState(0);
  const [previousDirectIncome, setPreviousDirectIncome] = useState(0);
  const [directCount, setDirectCount] = useState(0);
  const [partnerCount, setPartnerCount] = useState(0);
  const [totalStake, setTotalStake] = useState(0);
  // const [totalIncome, setTotalIncome] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [referralEarnings, setReferralEarnings] = useState(0);
  const [earningsAll, setEarningsAll] = useState<number>(0);
  const [totalWithdrawals, setTotalWithdrawals] = useState<number>(0);
  // const [totalRewardsWithInitial, setTotalRewardsWithInitial] = useState<number>(0);
  const [totalRewards, setTotalRewards] = useState<number>(0);
  // const [dailyReward, setDailyReward] = useState<number>(0);
  
  const handleGetContractDetails = async () => {};





  const { isLoading, refetch } = useUserStakingData();
  const { setTransaction, loading } = useContext(TransactionContext);


 

 

 





  const { userData: user } = useUserStakingData();

  const handleGetUserData = useCallback(async () => {
    if (account && chainId) {
      const { provider } = library;
      try {
        const earningsAll = await getEarningsAll(account, provider, chainId, account);
        setEarningsAll(Number(earningsAll));

        const directIncome = await getUserDirectReferrerIncome24hrs(account, provider, chainId);
        const partnerIncome = await getUserPartnerReferrerIncome24hrs(account, provider, chainId);
        const directCount = await getUserDirectReferrerCount(account, provider, chainId);
        const partnerCount = await getUserPartnerReferrerCount(account, provider, chainId);
        const totalStakedAmount = await getUserTotalStaked(account, provider, chainId);
        const { referralEarnings } = await getUserStakingDetails(account, provider, chainId);
        const totalClaimedIncome = await getUserTotalClaimed(account, provider, chainId);
        const totalWithdrawals = await getTotalWithdrawals(account, provider, chainId, account);

        const directIncomeNumber = Number(directIncome);
        const partnerIncomeNumber = Number(partnerIncome);
        const referralEarningsNumber = Number(referralEarnings);
        const totalClaimedIncomeNumber = Number(totalClaimedIncome);
        const totalWithdrawalsNumber = Number(totalWithdrawals);

        const totalIncome = directIncomeNumber + partnerIncomeNumber + referralEarningsNumber + totalClaimedIncomeNumber  ;
        setDirectIncome(directIncomeNumber);
        setPartnerIncome(partnerIncomeNumber);
        setDirectCount(Number(directCount));
        setPartnerCount(Number(partnerCount));
        setTotalStake(Number(totalStakedAmount));
        // setTotalIncome(totalIncome);
        setTotalClaimed(totalClaimedIncomeNumber);
        setReferralEarnings(referralEarningsNumber);
        setTotalWithdrawals(totalWithdrawalsNumber);
        setPreviousDirectIncome(directIncomeNumber);
        setPreviousPartnerIncome(partnerIncomeNumber);
        setTotalRewards(totalRewards);

        const level = await checkUserLevel(account, provider, chainId, account);
        setUserLevel(level);
        
        const status = await checkStatus(account, provider, chainId, account);
        setUserStatus(status);
      } catch (error: any) {
        console.log(error);
      }
      
    }
  }, [account, library, chainId]);
  
  
  

  useEffect(() => {
    handleGetUserData();
  }, [handleGetUserData]);

  const handleOpenReferralsModal = () => {
    setOpenReferralsModal(true);
  };

  const handleCloseReferralsModal = () => {
    setOpenReferralsModal(false);
  };

  // const notifySuccess = () => {
  //   toast.success("Success!", {
  //     position: toast.POSITION.TOP_LEFT,
  //     autoClose: 3000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //   });
  // };

  // const notifyError = (message: string) => {
  //   toast.error(message, {
  //     position: toast.POSITION.TOP_LEFT,
  //     autoClose: 5000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //   });
  // };

  const onClaimEarnings = async () => {
    if (account && chainId) {
      const { provider } = library;
      try {
        await withdrawReferralEarnings(account, provider, chainId);
        await handleGetUserData();
      } catch (error: any) {
        Error("Failed to claim earnings: " + error.message);
      }
    }
  };

  const handleClaimAll = async () => {
    if (account && chainId) {
      const { provider } = library;
      try {
        await claimAll(account, provider, chainId);
        Error();
        await handleGetUserData();
      } catch (error: any) {
        Error("Failed to claim all: " + error.message);
      }
    }
  };

  // const handleApprove = async () => {
  //   if (!account || !chainId) return;
  //   try {
  //     setTransaction({ loading: true, status: "pending" });
  //     const { setApprove } = await import("../../utils/userMethods");
  //     await setApprove(account, library?.provider, chainId);
  //     await refetch();
  //     setTransaction({ loading: true, status: "success" });
  //   } catch (error: any) {
  //     if (error?.code === 4001) {
  //       return setTransaction({
  //         loading: true,
  //         status: "error",
  //         message: error.message,
  //       });
  //     }
  //     setTransaction({ loading: true, status: "error" });
  //   }
  // };

  
  // const renderMethods = (
  //   <div className="form_container">
  //     {<Deposit setDepositModal={setDepositModal} />}
     
      
  //   </div>
  // );





  
 
  return (
    <>

{/* <ToastContainer /> */}
      <div className="card-container2">

      <div

        className="profile-page"
        style={{
          backgroundImage: userData?.bannerImg ? `url(${userData.bannerImg})` : undefined,
        }}
      >
        <div className="mx">
          <div className="container">
            <div className="camera-content" onClick={() => setOpenBannerModal(true)}>
              <div className="camera-icon">{/* SVG for camera icon */}</div>
              <div className="text">
                <h6>Change Cover</h6>
              </div>
            </div>
            <div className="profile-content">
              <div className="profile-img">
                <img src={userData?.profileImg ?? ProfileImg} alt="" />
                <div className="upload-content" onClick={() => setOpenProfileModal(true)}>
                  {/* SVG for camera icon */}
                </div>
              </div>
              <h5>{userData?.username ?? formatAddress(account ?? "")}</h5>

            </div>
          </div>

        </div>

      </div>

      <div className="edit-profile-content">

        <div className="edit-profile" onClick={() => setEditProfile(true)}>
          {/* SVG for pen icon */}
          <h5>Edit</h5>
        </div>
      </div>
      <div className="mx">
        <div className="referrer-details">
          {user.referrer && (
            <>
              <h5>Sponsor {formatAddress(user.referrer)}</h5>
              <button className="open-referrals-btn" onClick={handleOpenReferralsModal}>
                View Referrals
              </button>
            </>
          )}
        </div>
      </div>
        <div className="card-container">{/* Your card content */}</div>
        <div className="card2-container">
      <div className="card2">
        
        <h5>TOTAL WITHDRAW</h5>
        <p>{totalWithdrawals} USDT</p>
      </div>
      <div className="card2">
        <h5>TOTAL CLAIMED</h5>
        <p>{totalClaimed} USDT</p>
      </div>
      <div className="card2">
        
        <h5>DIRECT</h5>
        <p>{directCount}</p>
        <div className="highlight">
          <p className={directIncome > 0 ? 'income-increase' : ''}>
            {directIncome} USDT
            {directIncome > 0 && <span className="arrow-up">↑</span>}
          </p>
        </div>
      </div>

      <div className="card2">
       
        <h5>TOTAL TEAM</h5>
        <p>{partnerCount + directCount}</p>
        <div className="highlight">
          <p className={partnerIncome > 0 ? 'income-increase' : ''}>
            {partnerIncome} USDT
            {partnerIncome > 0 && <span className="arrow-up">↑</span>}
          </p>
        </div>
      </div>

      <div className="card2">
        
        <h5>ACTIVE INCOME</h5>
        <p>{referralEarnings} USDT</p>
        <button onClick={onClaimEarnings}>Withdraw</button>
      </div>

     
      <CardWithClock/> 

      <div className="card2">
        <h5>ID STATUS</h5>
        <p>{userStatus}</p>
      </div>

      <div className="card2">
        <h5>MY RANK</h5>
        <p>{userLevel}</p>
      </div>

      <div className="card2">
        <h5>MY STAKED</h5>
        <p>{totalStake} USDT</p>
      </div>

      <div className="card2">
        <h5>wallet fund</h5>
        <p><FarmHeader/>
        </p>
      </div>

        </div>

      </div>
      





 



{openBannerModal && (
  <ChangeCover
    onClose={() => setOpenBannerModal(false)}
    onUpdate={handleGetUserData}
  />
)}

{openProfileModal && (
  <ChangeProfileImage
    onClose={() => setOpenProfileModal(false)}
    onUpdate={handleGetUserData}
  />
)}

{editProfile && (
  <EditProfile
    onClose={() => setEditProfile(false)}
    // onUpdate={handleGetUserData}
  />
)}

{depositModal && (
  <DepositModal
    key={"deposit"}
    modal={depositModal ? true : false}
    handleClose={() => setDepositModal(null)}
    data={depositModal}
    handleGetContractDetails={handleGetContractDetails}
  />
)}

        <ReferralsModal
          isOpen={openReferralsModal}
          onClose={handleCloseReferralsModal}
          referralEarnings={referralEarnings}
          referredUsers={directCount + partnerCount}
          referralLevel={4}
          onClaimEarnings={onClaimEarnings}
        />
      
    </>
  );
};


export default ProfilePage;
