import { doc, DocumentData, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { IUserProfileData } from "../constants/types";

const USER_COLLECTION_NAME = "Users";

export const getUserDoc = async (id: string) => {
  const docRef = doc(db, USER_COLLECTION_NAME, id.toLowerCase());
  const userData = (await getDoc(docRef)).data() as Omit<IUserProfileData, "id">;

  return { id: id, ...userData };
};

export const updateUserDoc = async (id: string, data: DocumentData) => {
  const docRef = doc(db, USER_COLLECTION_NAME, id.toLowerCase());
  const userData = await getDoc(docRef);

  if (userData.exists()) {
    await updateDoc(docRef, data);
  } else {
    await setDoc(docRef, data);
  }
};
