// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getStorage } from "firebase/storage";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAXult8Y6FW5dZZf2l81htN8WVbOj31CJM",
//   authDomain: "super10club.firebaseapp.com",
//   projectId: "super10club",
//   storageBucket: "super10club.appspot.com",
//   messagingSenderId: "458179858067",
//   appId: "1:458179858067:web:a77db3d521e6de10360766",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const db = getFirestore(app);
// export const storage = getStorage(app);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration


const firebaseConfig = {
  apiKey: "AIzaSyCtpDPx1PtODtEbzO4dpxI893XMQYQjexs",
  authDomain: "database-c9fc8.firebaseapp.com",
  projectId: "database-c9fc8",
  storageBucket: "database-c9fc8.appspot.com",
  appId: "1:262472202133:web:840069d594311f5860a89c",
  messagingSenderId: "262472202133",
  
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
