import React, { useState, useEffect } from "react";
import { ethers, Contract } from "ethers";
import { useSnackbar } from 'notistack';
import './TokenTradingApp.scss';  

const TokenTradingApp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [account, setAccount] = useState<string | null>(null);
  const [usdtAmount, setUsdtAmount] = useState<string>("");
  const [tokenAmount, setTokenAmount] = useState<string>("");
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [contract, setContract] = useState<Contract | null>(null);
  const [usdtContract, setUsdtContract] = useState<Contract | null>(null);
  const [tokenContract, setTokenContract] = useState<Contract | null>(null);
  const [tokenPrice, setTokenPrice] = useState<string | null>(null);
  const [isBuyingAllowed, setIsBuyingAllowed] = useState<boolean>(true);
  const [isSellingAllowed, setIsSellingAllowed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const USDT_ADDRESS = "0x741F3c2B0BdD5D495eF893004b0EB1EaB07B8919";
  const TOKEN_ADDRESS = "0xD974d9B3b48f6b4F9f473Ce3c175670b0A60A16a";
  const CONTRACT_ADDRESS = "0x6468a3dA09E6AF89d11130BaC8140296ea4adA03";

  useEffect(() => {
    if (window.ethereum) {
      const init = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        setProvider(provider);

        const usdt = new ethers.Contract(USDT_ADDRESS, [
          "function allowance(address owner, address spender) external view returns (uint256)",
          "function approve(address spender, uint256 amount) external returns (bool)",
          "function transferFrom(address sender, address recipient, uint256 amount) external returns (bool)",
          "function balanceOf(address account) external view returns (uint256)",
        ], signer);
        setUsdtContract(usdt);

        const token = new ethers.Contract(TOKEN_ADDRESS, [
          "function transfer(address recipient, uint256 amount) external returns (bool)",
          "function balanceOf(address account) external view returns (uint256)",
        ], signer);
        setTokenContract(token);

        const tradingContract = new ethers.Contract(CONTRACT_ADDRESS, [
          "function buyTokens(uint256 usdtAmount) external",
          "function sellTokens(uint256 tokenAmount) external",
          "function tokenPrice() external view returns (uint256)",
          "function isBuyingAllowed() external view returns (bool)",
          "function isSellingAllowed() external view returns (bool)",
        ], signer);
        setContract(tradingContract);

        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        setAccount(accounts[0]);

        const price = await tradingContract.tokenPrice();
        setTokenPrice(ethers.utils.formatUnits(price, 18));
        const buyStatus = await tradingContract.isBuyingAllowed();
        setIsBuyingAllowed(buyStatus);
        const sellStatus = await tradingContract.isSellingAllowed();
        setIsSellingAllowed(sellStatus);
      };
      init();
    } else {
      alert("Please install MetaMask or another Web3 wallet");
    }
  }, []);

  const handleBuyTokens = async () => {
    if (!contract || !usdtContract) return;
    if (!usdtAmount || parseFloat(usdtAmount) <= 0) {
      enqueueSnackbar("Please enter a valid USDT amount.", { variant: 'error' });
      return;
    }
    try {
      setLoading(true);
      const usdtAmountWei = ethers.utils.parseUnits(usdtAmount, 18);

      const allowance = await usdtContract.allowance(account, CONTRACT_ADDRESS);
      if (allowance.lt(usdtAmountWei)) {
        await handleApprove(usdtAmount);
      }

      const tx = await contract.buyTokens(usdtAmountWei);
      await tx.wait();
      enqueueSnackbar("Tokens bought successfully!", { variant: 'success' });
      setUsdtAmount("");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to buy tokens. Please check the console for more details.", { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSellTokens = async () => {
    if (!contract || !tokenContract) return;
    if (!tokenAmount || parseFloat(tokenAmount) <= 0) {
      enqueueSnackbar("Please enter a valid token amount.", { variant: 'error' });
      return;
    }
    try {
      setLoading(true);
      const tokenAmountWei = ethers.utils.parseUnits(tokenAmount, 18);

      const tx = await contract.sellTokens(tokenAmountWei);
      await tx.wait();
      enqueueSnackbar("Tokens sold successfully!", { variant: 'success' });
      setTokenAmount("");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to sell tokens. Please check the console for more details.", { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (amount: string) => {
    if (!usdtContract) return;
    try {
      const tx = await usdtContract.approve(CONTRACT_ADDRESS, ethers.utils.parseUnits(amount, 18));
      await tx.wait();
      enqueueSnackbar("USDT allowance approved successfully!", { variant: 'success' });
    } catch (err) {
      console.error("Approval error:", err);
      enqueueSnackbar("Failed to approve USDT allowance.", { variant: 'error' });
    }
  };

  return (
    <div className="token-trading-app">
            <h1></h1>

      <h1></h1>

      <div className="trade-section">
      <p className="account-info">Account: {account}</p>

        <h2>Buy Tokens</h2>
        <input
          type="number"
          value={usdtAmount}
          onChange={(e) => setUsdtAmount(e.target.value)}
          placeholder="Enter USDT amount"
          className="input-field"
        />
        <button onClick={handleBuyTokens} disabled={!isBuyingAllowed || loading} className="action-button">
          {loading ? "Processing..." : "Buy Tokens"}
        </button>
      </div>

      <div className="trade-section">
      <div className="token-price">
        <h3>Current Token Price: {tokenPrice} USDT</h3>
      </div>
        <h2>Sell Tokens</h2>
        <input
          type="number"
          value={tokenAmount}
          onChange={(e) => setTokenAmount(e.target.value)}
          placeholder="Enter Token amount"
          className="input-field"
        />
        <button onClick={handleSellTokens} disabled={!isSellingAllowed || loading} className="action-button">
          {loading ? "Processing..." : "Sell Tokens"}
        </button>
      </div>

      
    </div>
  );
};

export default TokenTradingApp;
