import moment from 'moment';
import { IUserRewardDetails } from '../store/types';

export const getSlicedValue = (val: string, sliceLength = 6) => {
  return (
    val.slice(0, sliceLength) + '...' + val.slice(val.length - sliceLength)
  );
};

export const getAverageApr = (apr: number) => `${apr - 1}% - ${apr + 3}%`;

interface IGetDiffInDays {
  startDate: number | string | Date;
  endDate: number | string | Date;
}

export const getDiffInDays = ({ startDate, endDate }: IGetDiffInDays) => {
  const initialDate = moment(startDate);
  const currentDate = moment();

  const normalizedEndDate =
    typeof endDate === 'number'
      ? endDate
      : typeof endDate === 'string'
      ? new Date(endDate).getTime()
      : endDate.getTime();

  if (Date.now() > normalizedEndDate) {
    return Math.abs(initialDate.diff(normalizedEndDate, 'days'));
  }
  return Math.abs(initialDate.diff(currentDate, 'days'));
};

export const getTotalValue = (
  data: IUserRewardDetails[],
  user_address: string
) => {
  return data
    .filter(
      (f) =>
        f.address.toLocaleLowerCase() === user_address.toLocaleLowerCase()
    )
    .reduce((a, b) => a + b.earnedBonus, 0);
};
