import React, { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";

const FarmStats: React.FC = () => {
  const { account, library, chainId } = useWeb3React();
  const [totalStaked, setTotalStaked] = useState("0");

  const handleGetContractDetails = useCallback(async () => {
    if (account && chainId) {
      try {
        const { getContractDetails } = await import("../../utils/contractMethods");
        const details = await getContractDetails(account, library?.provider, chainId);
        setTotalStaked(details.totalStaked);
      } catch (error: any) {
        console.log(error?.message);
      }
    }
  }, [account, chainId, library]);

  useEffect(() => {
    handleGetContractDetails();
  }, [handleGetContractDetails]);

  return (
      <div className="flex">
        {/* <p>Total Staked </p> */}
        <b>&nbsp;
          {new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 4,
          }).format(Number(totalStaked))}{" "}
            USDT
        </b>
      </div>
  );
};

export default FarmStats;
