import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { AnimatePresence, motion } from "framer-motion";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Backdrop from "./Backdrop";
import "./Modal.scss";
import { modalVaraints } from "../../helpers/animation";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile.svg";
import { ReactComponent as ExternalLink } from "../../assets/icons/external_link.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import Button from "../Button";

interface IAccountModal {
  modal: boolean;
  handleClose?: () => void;
}

const AccountModal: React.FC<IAccountModal> = ({ modal, handleClose }) => {
  const { account, deactivate } = useWeb3React();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;

    const clear = setTimeout(() => setCopied(false), 5000);

    return () => {
      clearTimeout(clear);
    };
  }, [copied]);

  return (
    <Backdrop handleClose={handleClose} isOpen={modal}>
      <AnimatePresence exitBeforeEnter>
        {modal && (
          <motion.div
            className="account_modal"
            onClick={(e) => e.stopPropagation()}
            variants={modalVaraints}
            animate="animate"
            initial="initial"
            exit="exit"
          >
            <div className="account_modal-content">
              <div className="modal-header">
                <h3>Account</h3>
                <div className="close" onClick={handleClose}>
                  <CloseIcon />
                </div>
              </div>
              <p className="mb-10">Connected with MetaMask</p>
              <div className="user_details">
                <div className="flex-column">
                  <div className="flex mb-20">
                    <ProfileIcon />
                    <h2>{`${account?.slice(0, 6)}...${account?.slice(account?.length - 6)}`}</h2>
                  </div>
                  <div data-position="flex" style={{ gap: "1rem" }}>
                    <a
                      href={`https://bscscan.com/address/${account}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-position="flex"
                    >
                      <span>View on Explorer</span>
                      <ExternalLink />
                    </a>
                    {account && (
                      <CopyToClipboard text={account} onCopy={() => setCopied(true)}>
                        {copied ? (
                          <span>Copied</span>
                        ) : (
                          <div className="pointer" data-position="flex">
                            <span>Copy Address</span>
                            <Copy />
                          </div>
                        )}
                      </CopyToClipboard>
                    )}
                  </div>
                </div>
                <Button
                  variant="error"
                  onClick={() => {
                    deactivate();
                    if (handleClose) handleClose();
                  }}
                >
                  Disconnect
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Backdrop>
  );
};

export default AccountModal;
